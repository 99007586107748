import React, { useState, useEffect } from "react";
import useForm from "react-hook-form";
import BeatLoader from "react-spinners/BeatLoader";
import Modal from "react-modal";
import ModaTerms from "./contents/modalTerms";

const PartnerForm = (props) => {
  const { register, handleSubmit, errors } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [states, setStates] = useState([]);
  const [formPosted, setFormPosted] = useState(false);
  const [formError, setFormError] = useState(false);

  useEffect(() => {
    fetch("https://api.myvivcar.com/api/sp/states")
      .then((temp) => temp.json())
      .then((data) => {
        setStates(data);
      });
  }, []);

  const onSubmit = async (data) => {
    setIsLoading(true);
    const url = "https://api.myvivcar.com/api/provider/new";
    const regData = {
      company: data.bizName,
      fname: data.fname,
      lname: data.lname,
      email: data.email,
      website: data.website,
      phone: data.phone,
      address: data.bizAddress,
      state: data.state,
      zone: data.zone,
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(regData),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const json = await response.json();
      if (json.status === "error") {
        setFormError(true);
      }
      setIsLoading(false);
      setFormPosted(true);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Modal
        ariaHideApp={false}
        isOpen={modal}
        onRequestClose={() => setModal(false)}
        contentLabel="Terms and Conditions"
      >
        <ModaTerms onclose={() => setModal(false)} />
      </Modal>
      <div className="become-partner">
        {!formPosted ? (
          <>
            <p className="title is-4 has-text-primary">
              Become an Autocare Partner
            </p>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="field is-horizontal">
                <div className="field-body">
                  <div className="field">
                    <p className="control is-expanded">
                      <input
                        name="fname"
                        className="input is-medium is-static is-success"
                        type="text"
                        placeholder="First Name"
                        ref={register({ required: true })}
                        value={props.fname}
                      />
                    </p>
                    {errors.fname && <span>This field is required</span>}
                  </div>
                  <div className="field">
                    <p className="control is-expanded">
                      <input
                        className="input is-medium is-static"
                        type="text"
                        placeholder="Last Name"
                        value={props.lname}
                        name="lname"
                        ref={register({ required: true })}
                      />
                    </p>
                    {errors.lname && <span>This field is required</span>}
                  </div>
                </div>
              </div>

              <div className="field">
                <div className="control">
                  <input
                    className="input is-medium is-static"
                    type="text"
                    placeholder="Business Name"
                    value={props.bizName}
                    name="bizName"
                    ref={register({ required: true })}
                  />
                </div>
                {errors.bizName && <span>This field is required</span>}
              </div>
              <div className="field">
                <div className="control">
                  <input
                    className="input is-medium is-static"
                    type="text"
                    placeholder="Business Address"
                    value={props.bizAddress}
                    name="bizAddress"
                    ref={register({ required: true })}
                  />
                </div>
                {errors.bizAddress && <span>This field is required</span>}
              </div>
              <div className="field is-horizontal">
                <div className="field-body">
                  <div className="field">
                    <div className="control">
                      <input
                        className="input is-medium is-static"
                        type="email"
                        placeholder="Email"
                        value={props.email}
                        name="email"
                        ref={register({
                          required: true,
                          pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        })}
                      />
                    </div>
                    {errors.email && <span>This is not a valid email.</span>}
                  </div>
                  <div className="field">
                    <div className="control">
                      <input
                        className="input is-medium is-static"
                        type="tel"
                        placeholder="Phone Number"
                        value={props.phone}
                        name="phone"
                        maxLength="11"
                        minLength=""
                        ref={register({ required: true })}
                      />
                    </div>
                    {errors.phone && <span>This field is required</span>}
                  </div>
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <input
                    className="input is-medium is-static"
                    type="text"
                    placeholder="Instagram handle e.g @mycarviva"
                    value={props.website}
                    name="website"
                    ref={register({ required: false })}
                  />
                </div>
                {errors.website && (
                  <span>This needs to be the full address, with "wwww"</span>
                )}
              </div>
              <div className="field is-horizontal">
                <div className="field-body">
                  <div className="field">
                    <p className="control is-expanded">
                      <input
                        className="input is-medium is-static"
                        type="text"
                        placeholder="Zone (Ikeja, Surulere, Ikoyi etc)"
                        value={props.zone}
                        name="zone"
                        ref={register({ required: true })}
                      />
                    </p>
                    {errors.zone && <span>This field is required</span>}
                  </div>
                  <div className="field is-medium">
                    <p className="control is-expanded">
                      <select
                        className="select is-medium is-static"
                        style={{
                          border: "none",
                          background: "#f3f4f6",
                          height: "47px",
                          paddingRight: "3rem",
                          paddingLeft: "1rem",
                          fontSize: "0.9rem",
                        }}
                        name="state"
                        id="state"
                        ref={register({ required: true })}
                        defaultValue={""}
                      >
                        <option value="" selected disabled>
                          State
                        </option>
                        {states.map((state) => (
                          <option key={state.id} value={state.name}>
                            {state.name}
                          </option>
                        ))}
                      </select>
                    </p>
                    {errors.state && <span>This field is required</span>}
                  </div>
                </div>
              </div>
              <p>
                By Signing up, I agree to Carviva's{" "}
                <strong>
                  <a
                    href="/"
                    className="has-text-primary"
                    onClick={(e) => {
                      e.preventDefault();
                      setModal(true);
                    }}
                  >
                    Terms of Use
                  </a>
                </strong>{" "}
                & acknowledge that I have read the{" "}
                <strong>
                  <a
                    href="/"
                    className="has-text-primary"
                    onClick={(e) => {
                      e.preventDefault();
                      setModal(true);
                    }}
                  >
                    Privacy Policy
                  </a>
                </strong>
                .
              </p>
              {formError && (
                <span
                  style={{
                    color: "red",
                    fontStyle: "italic",
                    margin: "6px 0px",
                    display: "block",
                  }}
                >
                  Phone number is already in use.
                </span>
              )}
              <div
                className="field is-grouped is-hidden-touch"
                style={{
                  justifyContent: "space-between",
                  marginTop: "1rem",
                }}
              >
                <div className="control">
                  {isLoading === true ? (
                    <button
                      style={{ cursor: "default" }}
                      className="button is-link has-background-primary"
                    >
                      <BeatLoader
                        sizeUnit={"px"}
                        size={10}
                        color={"#fff"}
                        loading={isLoading}
                      />
                    </button>
                  ) : (
                    <button
                      style={{ cursor: "default" }}
                      className="button is-link has-background-primary"
                    >
                      Sign Up
                    </button>
                  )}
                </div>
                <div className="control">
                  <span>Already have an account? </span>
                  <a
                    href="https://sp.mycarviva.com/"
                    className="button has-text-primary has-text-weight-semibold"
                  >
                    Sign in
                  </a>
                </div>
              </div>
              <div
                className="field is-hidden-desktop"
                style={{
                  marginTop: "1rem",
                }}
              >
                <div className="control">
                  {isLoading === true ? (
                    <button
                      style={{ cursor: "default" }}
                      className="button is-link has-background-primary"
                    >
                      <BeatLoader
                        sizeUnit={"px"}
                        size={10}
                        color={"#fff"}
                        loading={isLoading}
                      />
                    </button>
                  ) : (
                    <button
                      style={{ cursor: "default" }}
                      className="button is-link has-background-primary"
                    >
                      Sign Up
                    </button>
                  )}
                </div>
                <div className="control" style={{ marginTop: "1rem" }}>
                  <span>Already have an account? </span>
                  <a
                    href="https://sp.mycarviva.com/"
                    className="button has-text-primary has-text-weight-semibold"
                  >
                    Sign in
                  </a>
                </div>
              </div>
            </form>
          </>
        ) : (
          <div style={{ paddingTop: 40, height: 490, textAlign: "center" }}>
            <h2
              style={{ paddingBottom: 40, color: "#21c292", fontSize: "26px" }}
            >
              Sign Up Successful.
            </h2>
            <p style={{ fontSize: "18px", opacity: 0.6, lineHeight: "32px" }}>
              Thanks for signup, look out for an email from us which will
              contain your username and password.
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default PartnerForm;
